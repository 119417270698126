import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
					<path fill="#FFFFFF" opacity="1.000000" stroke="none"
						d="
M1220.000000,2254.000000 
	C813.378235,2254.000000 407.756470,2254.000000 2.067352,2254.000000 
	C2.067352,1503.461304 2.067352,752.922668 2.067352,2.191985 
	C752.455505,2.191985 1502.911133,2.191985 2253.683350,2.191985 
	C2253.683350,752.666565 2253.683350,1503.333252 2253.683350,2254.000000 
	C1909.588379,2254.000000 1565.294189,2254.000000 1220.000000,2254.000000 
M1533.834717,2122.866943 
	C1556.272827,2112.764893 1579.034546,2103.318604 1601.087524,2092.436035 
	C1663.030151,2061.868652 1721.304932,2025.371094 1776.261841,1983.439697 
	C1827.764038,1944.143921 1874.990967,1900.255737 1918.634644,1852.542847 
	C1961.391968,1805.798706 1999.416016,1755.340820 2033.038208,1701.612427 
	C2076.812988,1631.660522 2112.194336,1557.702148 2138.978271,1479.635498 
	C2156.483643,1428.612549 2169.765137,1376.490967 2179.524170,1323.468384 
	C2188.186768,1276.404785 2193.442871,1228.885742 2195.450439,1181.125977 
	C2197.000244,1144.247437 2197.678467,1107.270386 2194.343262,1070.382080 
	C2192.222656,1046.926758 2190.658447,1023.416809 2188.230957,999.994995 
	C2184.496094,963.959473 2177.874268,928.388611 2169.910400,893.056091 
	C2151.697510,812.251526 2124.229248,734.705872 2087.916504,660.291626 
	C2064.232178,611.755737 2036.875366,565.358643 2006.160767,520.930176 
	C1973.552612,473.762726 1937.238525,429.615021 1897.608643,388.222626 
	C1870.433228,359.838593 1841.624634,333.153412 1811.440063,307.966736 
	C1749.610352,256.375000 1682.803345,212.311035 1611.055420,175.790344 
	C1559.086304,149.337357 1505.204712,127.548782 1449.598022,109.904785 
	C1365.254639,83.142654 1278.823120,67.425583 1190.618896,62.633377 
	C1146.469971,60.234741 1102.139404,60.437393 1057.876343,63.457355 
	C1026.383179,65.606064 995.091858,69.018814 963.908752,73.747215 
	C908.929871,82.083824 855.036621,95.006195 802.132263,112.061356 
	C708.767761,142.159912 621.226746,184.426559 539.629089,238.827942 
	C468.180939,286.462585 403.311462,341.834442 345.197449,405.163391 
	C308.903656,444.714081 275.562256,486.526337 245.503006,530.967346 
	C211.305695,581.526306 181.628342,634.609985 156.542984,690.277039 
	C131.343185,746.198059 110.996735,803.804626 95.846046,863.233887 
	C85.171005,905.107117 77.087799,947.447327 71.346558,990.321228 
	C66.758720,1024.581909 63.838520,1058.960693 62.625462,1093.403564 
	C61.584457,1122.961304 62.853222,1152.625488 63.813324,1182.219482 
	C64.436996,1201.443115 65.709381,1220.694824 67.819740,1239.809448 
	C70.841492,1267.178467 73.893143,1294.612061 78.715706,1321.700439 
	C88.629097,1377.384277 102.945152,1431.974487 121.619278,1485.446899 
	C142.410904,1544.982788 168.239243,1602.201172 199.006409,1657.173950 
	C243.602631,1736.855591 297.721832,1809.619995 361.115387,1875.271851 
	C384.665680,1899.661133 409.535583,1922.916260 435.130157,1945.162598 
	C489.533356,1992.448730 548.540649,2033.379150 611.576599,2068.420654 
	C664.026367,2097.577148 718.533020,2122.162842 775.120667,2142.167480 
	C873.212830,2176.844727 974.363403,2196.195068 1078.129639,2201.390869 
	C1115.312622,2203.252930 1152.649658,2203.694336 1189.870605,2200.313721 
	C1212.994507,2198.213379 1236.161621,2196.565186 1259.262939,2194.248779 
	C1300.303711,2190.134277 1340.677612,2182.131104 1380.753540,2172.547119 
	C1432.589355,2160.150391 1483.087158,2143.600342 1533.834717,2122.866943 
z"/>
					<path fill="#00AEEF" opacity="1.000000" stroke="none"
						d="
M1533.144531,2123.210938 
	C1483.087158,2143.600342 1432.589355,2160.150391 1380.753540,2172.547119 
	C1340.677612,2182.131104 1300.303711,2190.134277 1259.262939,2194.248779 
	C1236.161621,2196.565186 1212.994507,2198.213379 1189.870605,2200.313721 
	C1152.649658,2203.694336 1115.312622,2203.252930 1078.129639,2201.390869 
	C974.363403,2196.195068 873.212830,2176.844727 775.120667,2142.167480 
	C718.533020,2122.162842 664.026367,2097.577148 611.576599,2068.420654 
	C548.540649,2033.379150 489.533356,1992.448730 435.130157,1945.162598 
	C409.535583,1922.916260 384.665680,1899.661133 361.115387,1875.271851 
	C297.721832,1809.619995 243.602631,1736.855591 199.006409,1657.173950 
	C168.239243,1602.201172 142.410904,1544.982788 121.619278,1485.446899 
	C102.945152,1431.974487 88.629097,1377.384277 78.715706,1321.700439 
	C73.893143,1294.612061 70.841492,1267.178467 67.819740,1239.809448 
	C65.709381,1220.694824 64.436996,1201.443115 63.813324,1182.219482 
	C62.853222,1152.625488 61.584457,1122.961304 62.625462,1093.403564 
	C63.838520,1058.960693 66.758720,1024.581909 71.346558,990.321228 
	C77.087799,947.447327 85.171005,905.107117 95.846046,863.233887 
	C110.996735,803.804626 131.343185,746.198059 156.542984,690.277039 
	C181.628342,634.609985 211.305695,581.526306 245.503006,530.967346 
	C275.562256,486.526337 308.903656,444.714081 345.197449,405.163391 
	C403.311462,341.834442 468.180939,286.462585 539.629089,238.827942 
	C621.226746,184.426559 708.767761,142.159912 802.132263,112.061356 
	C855.036621,95.006195 908.929871,82.083824 963.908752,73.747215 
	C995.091858,69.018814 1026.383179,65.606064 1057.876343,63.457355 
	C1102.139404,60.437393 1146.469971,60.234741 1190.618896,62.633377 
	C1278.823120,67.425583 1365.254639,83.142654 1449.598022,109.904785 
	C1505.204712,127.548782 1559.086304,149.337357 1611.055420,175.790344 
	C1682.803345,212.311035 1749.610352,256.375000 1811.440063,307.966736 
	C1841.624634,333.153412 1870.433228,359.838593 1897.608643,388.222626 
	C1937.238525,429.615021 1973.552612,473.762726 2006.160767,520.930176 
	C2036.875366,565.358643 2064.232178,611.755737 2087.916504,660.291626 
	C2124.229248,734.705872 2151.697510,812.251526 2169.910400,893.056091 
	C2177.874268,928.388611 2184.496094,963.959473 2188.230957,999.994995 
	C2190.658447,1023.416809 2192.222656,1046.926758 2194.343262,1070.382080 
	C2197.678467,1107.270386 2197.000244,1144.247437 2195.450439,1181.125977 
	C2193.442871,1228.885742 2188.186768,1276.404785 2179.524170,1323.468384 
	C2169.765137,1376.490967 2156.483643,1428.612549 2138.978271,1479.635498 
	C2112.194336,1557.702148 2076.812988,1631.660522 2033.038208,1701.612427 
	C1999.416016,1755.340820 1961.391968,1805.798706 1918.634644,1852.542847 
	C1874.990967,1900.255737 1827.764038,1944.143921 1776.261841,1983.439697 
	C1721.304932,2025.371094 1663.030151,2061.868652 1601.087524,2092.436035 
	C1579.034546,2103.318604 1556.272827,2112.764893 1533.144531,2123.210938 
M863.000000,600.273987 
	C831.671692,600.189697 800.343323,600.137695 769.015198,600.007202 
	C753.799988,599.943787 739.088684,602.594910 725.429688,609.276123 
	C685.569702,628.773315 663.851013,660.643005 663.799927,705.267151 
	C663.524536,945.918762 663.668274,1186.570801 663.668945,1427.222778 
	C663.669067,1478.219604 663.443848,1529.218018 663.763367,1580.212891 
	C663.962952,1612.069580 677.095886,1638.204834 701.223511,1658.770630 
	C721.970459,1676.454834 746.488159,1683.392578 773.373291,1683.386475 
	C917.031128,1683.354004 1060.689575,1683.589233 1204.346069,1683.147827 
	C1227.246460,1683.077393 1250.240112,1680.911987 1273.014160,1678.221680 
	C1340.660156,1670.230591 1403.984497,1648.601929 1463.269531,1615.256226 
	C1489.410889,1600.552490 1515.350220,1585.344238 1537.295410,1564.517456 
	C1560.058960,1542.913940 1573.810913,1517.713379 1570.495361,1484.781738 
	C1564.547729,1425.705933 1495.719482,1375.557373 1431.403809,1407.311768 
	C1419.796143,1413.042847 1408.967407,1420.350464 1397.774414,1426.922363 
	C1379.976562,1437.372192 1362.682861,1448.828369 1344.275146,1458.063354 
	C1297.563110,1481.498413 1247.416016,1490.942993 1195.470215,1491.115112 
	C1117.142578,1491.374756 1038.813232,1491.242065 960.484924,1491.148193 
	C942.198730,1491.126343 924.734558,1487.170654 908.830200,1477.873779 
	C871.798523,1456.226685 854.439209,1423.199219 854.396301,1380.937378 
	C854.234985,1222.280640 854.288208,1063.623657 854.446350,904.966797 
	C854.455261,896.032532 855.273193,886.916321 857.158142,878.196899 
	C867.945862,828.295288 909.759644,794.834656 961.462708,794.730164 
	C1042.790771,794.565735 1124.120117,794.488342 1205.446899,794.857605 
	C1221.376343,794.929932 1237.442261,796.236450 1253.193237,798.631409 
	C1308.462891,807.035522 1358.803833,827.797729 1404.304199,860.420471 
	C1413.738403,867.184631 1423.633423,873.630127 1434.092896,878.581848 
	C1471.184448,896.141907 1506.200928,891.127258 1537.875977,865.777771 
	C1562.261597,846.262146 1574.331909,819.971008 1573.098267,788.746643 
	C1571.865112,757.536377 1557.093506,733.045471 1532.358643,714.164917 
	C1498.852173,688.588562 1463.177246,666.687622 1424.746582,649.321472 
	C1359.623047,619.893311 1291.401978,603.398926 1219.920044,601.800842 
	C1169.956177,600.683960 1119.963013,600.740601 1069.981934,600.564026 
	C1001.655151,600.322632 933.327332,600.357666 863.000000,600.273987 
M1135.001831,1124.263062 
	C1127.817383,1184.914062 1175.572510,1235.790405 1232.470947,1236.958740 
	C1285.376099,1238.044922 1336.241943,1196.753540 1335.326660,1134.957520 
	C1334.355713,1069.404175 1279.428589,1034.732666 1234.093262,1035.246826 
	C1186.824707,1035.782837 1141.434814,1071.943115 1135.001831,1124.263062 
M1655.119995,1344.063232 
	C1692.566772,1270.888550 1707.882324,1193.289307 1702.488159,1111.317017 
	C1699.288086,1062.689331 1688.358765,1016.006409 1669.734863,971.078003 
	C1657.450562,941.443420 1641.972778,913.536926 1623.300781,887.431335 
	C1617.929443,879.921692 1610.936890,875.062378 1601.303833,876.410278 
	C1591.471802,877.786072 1585.093140,884.044312 1581.874878,893.091614 
	C1578.033447,903.891296 1578.194824,914.683594 1584.484985,924.754639 
	C1587.128906,928.987610 1590.153076,932.981445 1592.852905,937.181396 
	C1647.563599,1022.293091 1665.989502,1114.731689 1647.323242,1214.098389 
	C1637.738892,1265.119019 1617.494263,1312.029663 1587.168457,1354.316406 
	C1580.702148,1363.332886 1578.516724,1372.898071 1580.004639,1383.511963 
	C1582.129272,1398.666626 1595.134644,1408.863525 1608.545410,1406.326660 
	C1615.706177,1404.971924 1620.447388,1400.346191 1624.041870,1394.643311 
	C1634.327637,1378.323975 1644.290405,1361.801025 1655.119995,1344.063232 
M1569.504639,1200.376343 
	C1571.569580,1181.885132 1575.089111,1163.419556 1575.415039,1144.897705 
	C1576.249390,1097.469727 1565.082520,1052.566284 1543.250122,1010.388428 
	C1532.337402,989.306396 1519.069824,969.800781 1503.847900,951.600525 
	C1499.256470,946.110779 1494.132812,941.344360 1486.719116,940.425659 
	C1477.279541,939.255981 1469.607910,942.789612 1464.402954,950.692749 
	C1458.911011,959.031494 1459.072632,967.797485 1464.735962,975.960022 
	C1468.900391,981.962402 1473.988525,987.314880 1478.371460,993.176514 
	C1508.400024,1033.335327 1526.754517,1078.114136 1529.562622,1128.410400 
	C1533.016602,1190.277466 1513.475586,1245.009399 1475.753540,1293.634644 
	C1472.285889,1298.104614 1468.323608,1302.206421 1465.038940,1306.799561 
	C1459.228271,1314.925171 1459.297363,1325.093750 1464.786865,1332.979736 
	C1470.312866,1340.918091 1479.991943,1345.051147 1489.340698,1341.802856 
	C1494.369629,1340.055664 1499.304443,1336.367920 1502.859863,1332.310059 
	C1535.963135,1294.528931 1558.255737,1251.159180 1569.504639,1200.376343 
M1415.031494,1157.708130 
	C1413.666382,1166.242554 1412.758545,1174.880493 1410.855103,1183.293213 
	C1403.572876,1215.476562 1388.117065,1243.488892 1366.849609,1268.529785 
	C1359.308716,1277.408813 1360.127075,1288.546631 1368.187988,1295.141479 
	C1376.108887,1301.621826 1386.416626,1300.585205 1393.870972,1291.886108 
	C1435.429077,1243.389282 1455.522583,1187.688965 1450.470825,1123.427734 
	C1446.600464,1074.194092 1427.149048,1031.635376 1396.150024,993.859009 
	C1394.258667,991.554016 1392.211548,989.268921 1389.856689,987.473145 
	C1383.271118,982.451355 1373.859741,982.905579 1367.694824,988.253113 
	C1361.599365,993.540161 1359.703369,1002.999390 1363.661255,1010.110718 
	C1365.261475,1012.985840 1367.551270,1015.492981 1369.635376,1018.080505 
	C1402.055664,1058.332520 1418.612061,1103.843506 1415.031494,1157.708130 
z"/>
					<path fill="#FEFFFF" opacity="1.000000" stroke="none"
						d="
M864.000000,600.274048 
	C933.327332,600.357666 1001.655151,600.322632 1069.981934,600.564026 
	C1119.963013,600.740601 1169.956177,600.683960 1219.920044,601.800842 
	C1291.401978,603.398926 1359.623047,619.893311 1424.746582,649.321472 
	C1463.177246,666.687622 1498.852173,688.588562 1532.358643,714.164917 
	C1557.093506,733.045471 1571.865112,757.536377 1573.098267,788.746643 
	C1574.331909,819.971008 1562.261597,846.262146 1537.875977,865.777771 
	C1506.200928,891.127258 1471.184448,896.141907 1434.092896,878.581848 
	C1423.633423,873.630127 1413.738403,867.184631 1404.304199,860.420471 
	C1358.803833,827.797729 1308.462891,807.035522 1253.193237,798.631409 
	C1237.442261,796.236450 1221.376343,794.929932 1205.446899,794.857605 
	C1124.120117,794.488342 1042.790771,794.565735 961.462708,794.730164 
	C909.759644,794.834656 867.945862,828.295288 857.158142,878.196899 
	C855.273193,886.916321 854.455261,896.032532 854.446350,904.966797 
	C854.288208,1063.623657 854.234985,1222.280640 854.396301,1380.937378 
	C854.439209,1423.199219 871.798523,1456.226685 908.830200,1477.873779 
	C924.734558,1487.170654 942.198730,1491.126343 960.484924,1491.148193 
	C1038.813232,1491.242065 1117.142578,1491.374756 1195.470215,1491.115112 
	C1247.416016,1490.942993 1297.563110,1481.498413 1344.275146,1458.063354 
	C1362.682861,1448.828369 1379.976562,1437.372192 1397.774414,1426.922363 
	C1408.967407,1420.350464 1419.796143,1413.042847 1431.403809,1407.311768 
	C1495.719482,1375.557373 1564.547729,1425.705933 1570.495361,1484.781738 
	C1573.810913,1517.713379 1560.058960,1542.913940 1537.295410,1564.517456 
	C1515.350220,1585.344238 1489.410889,1600.552490 1463.269531,1615.256226 
	C1403.984497,1648.601929 1340.660156,1670.230591 1273.014160,1678.221680 
	C1250.240112,1680.911987 1227.246460,1683.077393 1204.346069,1683.147827 
	C1060.689575,1683.589233 917.031128,1683.354004 773.373291,1683.386475 
	C746.488159,1683.392578 721.970459,1676.454834 701.223511,1658.770630 
	C677.095886,1638.204834 663.962952,1612.069580 663.763367,1580.212891 
	C663.443848,1529.218018 663.669067,1478.219604 663.668945,1427.222778 
	C663.668274,1186.570801 663.524536,945.918762 663.799927,705.267151 
	C663.851013,660.643005 685.569702,628.773315 725.429688,609.276123 
	C739.088684,602.594910 753.799988,599.943787 769.015198,600.007202 
	C800.343323,600.137695 831.671692,600.189697 864.000000,600.274048 
z"/>
					<path fill="#FEFFFF" opacity="1.000000" stroke="none"
						d="
M1135.201172,1123.402832 
	C1141.434814,1071.943115 1186.824707,1035.782837 1234.093262,1035.246826 
	C1279.428589,1034.732666 1334.355713,1069.404175 1335.326660,1134.957520 
	C1336.241943,1196.753540 1285.376099,1238.044922 1232.470947,1236.958740 
	C1175.572510,1235.790405 1127.817383,1184.914062 1135.201172,1123.402832 
z"/>
					<path fill="#FCFEFF" opacity="1.000000" stroke="none"
						d="
M1654.750000,1344.710693 
	C1644.290405,1361.801025 1634.327637,1378.323975 1624.041870,1394.643311 
	C1620.447388,1400.346191 1615.706177,1404.971924 1608.545410,1406.326660 
	C1595.134644,1408.863525 1582.129272,1398.666626 1580.004639,1383.511963 
	C1578.516724,1372.898071 1580.702148,1363.332886 1587.168457,1354.316406 
	C1617.494263,1312.029663 1637.738892,1265.119019 1647.323242,1214.098389 
	C1665.989502,1114.731689 1647.563599,1022.293091 1592.852905,937.181396 
	C1590.153076,932.981445 1587.128906,928.987610 1584.484985,924.754639 
	C1578.194824,914.683594 1578.033447,903.891296 1581.874878,893.091614 
	C1585.093140,884.044312 1591.471802,877.786072 1601.303833,876.410278 
	C1610.936890,875.062378 1617.929443,879.921692 1623.300781,887.431335 
	C1641.972778,913.536926 1657.450562,941.443420 1669.734863,971.078003 
	C1688.358765,1016.006409 1699.288086,1062.689331 1702.488159,1111.317017 
	C1707.882324,1193.289307 1692.566772,1270.888550 1654.750000,1344.710693 
z"/>
					<path fill="#FBFEFF" opacity="1.000000" stroke="none"
						d="
M1569.271606,1201.185181 
	C1558.255737,1251.159180 1535.963135,1294.528931 1502.859863,1332.310059 
	C1499.304443,1336.367920 1494.369629,1340.055664 1489.340698,1341.802856 
	C1479.991943,1345.051147 1470.312866,1340.918091 1464.786865,1332.979736 
	C1459.297363,1325.093750 1459.228271,1314.925171 1465.038940,1306.799561 
	C1468.323608,1302.206421 1472.285889,1298.104614 1475.753540,1293.634644 
	C1513.475586,1245.009399 1533.016602,1190.277466 1529.562622,1128.410400 
	C1526.754517,1078.114136 1508.400024,1033.335327 1478.371460,993.176514 
	C1473.988525,987.314880 1468.900391,981.962402 1464.735962,975.960022 
	C1459.072632,967.797485 1458.911011,959.031494 1464.402954,950.692749 
	C1469.607910,942.789612 1477.279541,939.255981 1486.719116,940.425659 
	C1494.132812,941.344360 1499.256470,946.110779 1503.847900,951.600525 
	C1519.069824,969.800781 1532.337402,989.306396 1543.250122,1010.388428 
	C1565.082520,1052.566284 1576.249390,1097.469727 1575.415039,1144.897705 
	C1575.089111,1163.419556 1571.569580,1181.885132 1569.271606,1201.185181 
z"/>
					<path fill="#FAFEFF" opacity="1.000000" stroke="none"
						d="
M1415.212036,1156.815430 
	C1418.612061,1103.843506 1402.055664,1058.332520 1369.635376,1018.080505 
	C1367.551270,1015.492981 1365.261475,1012.985840 1363.661255,1010.110718 
	C1359.703369,1002.999390 1361.599365,993.540161 1367.694824,988.253113 
	C1373.859741,982.905579 1383.271118,982.451355 1389.856689,987.473145 
	C1392.211548,989.268921 1394.258667,991.554016 1396.150024,993.859009 
	C1427.149048,1031.635376 1446.600464,1074.194092 1450.470825,1123.427734 
	C1455.522583,1187.688965 1435.429077,1243.389282 1393.870972,1291.886108 
	C1386.416626,1300.585205 1376.108887,1301.621826 1368.187988,1295.141479 
	C1360.127075,1288.546631 1359.308716,1277.408813 1366.849609,1268.529785 
	C1388.117065,1243.488892 1403.572876,1215.476562 1410.855103,1183.293213 
	C1412.758545,1174.880493 1413.666382,1166.242554 1415.212036,1156.815430 
z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
